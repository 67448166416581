import React, { useEffect, useState } from "react";
import { useScrollYPosition } from "react-use-scroll-position";
import "./FooterOverlay.css";

import { Link, useNavigate } from "react-router-dom";
import { HiChevronDoubleLeft } from "react-icons/hi";

const FooterOverlay = ({
	linkWebsite,
	homeFooter,
	registrationMarketer,
	logoFooter,
}) => {
	const navigate = useNavigate();
	const scrolly = useScrollYPosition();
	const [showup, setShowup] = useState(false);

	useEffect(() => {
		if (scrolly >= 500) {
			setShowup(true);
		} else {
			setShowup(false);
		}
	}, [scrolly]);

	// handle open page in top position
	let goUpWindow = () => {
		window.scroll(0, 0);
	};

	// We use this filter to get all pages about category معلومات
	const information = homeFooter?.filter((page) =>
		page?.pageCategory?.filter((category) => category?.name === "معلومات")
	);

	// We use this filter to get all pages about category من نحن
	const aboutUsPage = homeFooter?.find(
		(page) =>
			page?.pageCategory?.some((category) => category?.name === "معلومات") &&
			page?.title === "من نحن"
	);

	// navigate to auth/merchant
	const navigateToMerchantRegister = () => {
		window.location.href = "https://store.atlbha.com/auth/merchant";
	};

	// navigate to auth/delegate
	const navigateToDelegateRegister = () => {
		window.location.href = "https://store.atlbha.com/auth/delegate";
	};

	return (
		<>
			<footer className='main-footer'>
				<div className='all'>
					<div className='container flex-column flex-lg-row  align-items-center align-items-lg-start '>
						<div className='box-left'>
							<a className='main-footer__logo' href='#0'>
								<img
									width='100%'
									height='100%'
									src={logoFooter}
									alt=''
									loading='lazy'
								/>
							</a>

							<ul>
								{linkWebsite?.length &&
									linkWebsite?.map((el, idx) => {
										return (
											<li key={el?.id}>
												<a href={el.link}>
													<img
														src={el.logo}
														alt='لوجو'
														width='100%'
														height='100%'
														loading='lazy'
													/>
												</a>
											</li>
										);
									})}
							</ul>
						</div>
						<nav className='box-right'>
							<ul className=''>
								{/*Footer about us*/}
								<li className='text-center text-lg-end about-us-box'>
									<h4
										onClick={() => {
											navigate(
												aboutUsPage`/page/${
													aboutUsPage?.id
												}/${encodeURIComponent(
													aboutUsPage?.title
														.replace(/[^a-zA-Z0-9\u0621-\u064A]+/g, "-")
														.toLowerCase()
												)}`
											);

											goUpWindow();
										}}
										className=''>
										{aboutUsPage?.title}
									</h4>

									<p
										dangerouslySetInnerHTML={{
											__html: aboutUsPage?.page_content,
										}}
										onClick={() => {
											navigate(
												`/page/${aboutUsPage.id}/${encodeURIComponent(
													aboutUsPage.title
														.replace(/[^a-zA-Z0-9\u0621-\u064A]+/g, "-")
														.toLowerCase()
												)}`
											);

											goUpWindow();
										}}
										className='about-us-content text-lg-end'
									/>
								</li>

								{/*Footer Info*/}
								<li className='text-center text-lg-end '>
									<h4 className=''>معلومات</h4>
									<ul className='text-center'>
										{homeFooter?.length !== 0 &&
											information?.length !== 0 &&
											homeFooter?.map((el) => (
												<li
													key={el?.id}
													className={
														el?.title === "الدورات التدريبية" ||
														el?.title === "من نحن"
															? "d-none"
															: "main-footer-link"
													}
													onClick={() => {
														navigate(
															`/page/${el.id}/${encodeURIComponent(
																el?.title
																	.replace(/[^a-zA-Z0-9\u0621-\u064A]+/g, "-")
																	.toLowerCase()
															)}`
														);

														goUpWindow();
													}}>
													{el?.title}
												</li>
											))}
									</ul>
								</li>

								<li className='text-center text-lg-end '>
									<h4 className=''>حسابي</h4>
									<ul className='text-center text-lg-end '>
										<li>
											<Link
												onClick={navigateToMerchantRegister}
												className='main-footer-link'>
												التسجيل كتاجر
											</Link>
										</li>

										{registrationMarketer === "active" && (
											<li>
												<Link
													onClick={navigateToDelegateRegister}
													className='main-footer-link'>
													التسجيل كمندوب
												</Link>
											</li>
										)}
									</ul>
								</li>

								<li className='text-center text-lg-end '>
									<h4 className=''> المساعدة</h4>
									<ul className='text-center text-lg-end '>
										<li className='main-footer-link'>
											<a
												className='main-footer-link'
												href='https://help.atlbha.com/'
												target='_blank'
												rel='noreferrer'>
												طلب مساعدة
											</a>
										</li>
										<li
											onClick={() => {
												navigate("/faqs");
												goUpWindow();
											}}
											className='main-footer-link'>
											الأسئلة الشائعة
										</li>
										<li>
											<a
												className='main-footer-link'
												href='https://help.atlbha.com/chat'
												target='_blank'
												rel='noreferrer'>
												الشكاوي والاقتراحات
											</a>
										</li>
									</ul>
								</li>
							</ul>
						</nav>
					</div>

					<div className='copyright-box container'>
						<hr />
						<h5>
							جميع الحقوق محفوظة <span> لمنصة شبكة اطلبها </span>2023 برمجة
							وتطوير{" "}
							<span>
								<a href='https://faz-it.net'>شبكة فاز</a>
							</span>
						</h5>
					</div>
				</div>
				<div
					className={showup === true ? "go-up show " : "go-up"}
					onClick={() => {
						window.scroll(0, 0);
					}}>
					<span>
						<HiChevronDoubleLeft />
					</span>
				</div>
			</footer>
		</>
	);
};

export default FooterOverlay;
