import React from "react";
import PageTitle from "../components/PagesTitle/PageTitle";

import { useState } from "react";
import axios from "axios";
import { useContext } from "react";
import AppContext from "../Context/AppContext";
import { useEffect } from "react";
import AlertModal from "../components/PagesBox/PasswordBackBox/AlertModal";
import { Helmet } from "react-helmet-async";

const ContactUs = () => {
	// to display alert modal
	const contextStore = useContext(AppContext);
	const { showAlertModal, setShowAlertModal, message, setMessage } =
		contextStore;
	// -----------------------------------------------------------------------------------

	// to set the contact inputs valuea
	const [contactUsInfo, setContactUsInfo] = useState({
		name: "",
		email: "",
		title: "",
		content: "",
	});
	const handleOnChangeContactInfo = (e) => {
		const { name, value } = e.target;
		setContactUsInfo((prevState) => {
			return { ...prevState, [name]: value };
		});
	};
	// -----------------------------------------------------------------------------------

	//  Handle Errors
	const [contactErrors, setContactErrors] = useState({
		name: "",
		email: "",
		title: "",
		content: "",
	});
	const resetErrors = () => {
		setContactErrors({
			name: "",
			email: "",
			title: "",
			content: "",
		});
	};
	// -----------------------------------------------------------------------------------

	// to close Alert modal after timer end
	useEffect(() => {
		if (showAlertModal) {
			setTimeout(() => {
				setShowAlertModal(false);
			}, 3000);
		}
	}, [showAlertModal]);
	// -----------------------------------------------------------------------------------

	// Add ContactUs Info FUNCTION
	const AddContactUsInfo = () => {
		resetErrors();
		let formData = new FormData();
		formData.append("name", contactUsInfo?.name);
		formData.append("email", contactUsInfo?.email);
		formData.append("title", contactUsInfo?.title);
		formData.append("content", contactUsInfo?.content);

		axios
			.post("https://backend.atlbha.com/api/atlobhaContactAdd", formData)
			.then((res) => {
				if (res?.data?.success === true && res?.data?.data?.status === 200) {
					// display modal with message
					setMessage(res?.data?.message?.ar);
					setShowAlertModal(true);

					// clear inputs
					setContactUsInfo({
						name: "",
						email: "",
						title: "",
						content: "",
					});
				} else {
					setContactErrors({
						name: res?.data?.message?.en?.name?.[0],
						email: res?.data?.message?.en?.email?.[0],
						title: res?.data?.message?.en?.title?.[0],
						content: res?.data?.message?.en?.content?.[0],
					});
				}
			});
	};
	return (
		<>
			<Helmet>
				<title>أطلبها | تواصل معنا</title>
				<meta
					name='description'
					content='ابدأ مع أطلبها بوابتك للتجارة الإلكترونية'
				/>
			</Helmet>
			<div className='commercial-flights contact-us'>
				<div className='container'>
					<PageTitle title={" اتصل بنا"} />
					<div className='content'>
						<div className='contact-info'>
							<div className=''>
								<h4>اتصل بنا</h4>
								<p>
									نحن سعداء أن نستقبل استفساراتكم واقتراحاتكم لنقدم لكم خدمة
									مميزة
								</p>
							</div>

							{/*
							<div className='icons '>
								<div className='group-one'>
									<div className='icon send-icon'>
										<SendIcon />
									</div>
									<div className='icon email-icon'>
										<EmailIcon />
									</div>
								</div>

								<div className='icon call-icon'>
									<CallIcon />
								</div>
							</div>
						
						*/}
						</div>
						<div className='contact-form-wrapper'>
							<p className='form-title'>نموذج التواصل- منصة اطلبها</p>
							<form action='#' className='register-form contact-form'>
								<div className='input-group'>
									<label>اسم المستخدم</label>
									<input
										name='name'
										value={contactUsInfo?.name}
										onChange={handleOnChangeContactInfo}
										type='text'
										placeholder=''
									/>
									{contactErrors?.name && (
										<div
											className={"wrong-text w-100"}
											style={{
												color: "red",
												fontSize: "16px",
											}}>
											{contactErrors?.name}
										</div>
									)}
								</div>

								<div className='input-group'>
									<label>البريد الالكتروني</label>
									<input
										name='email'
										value={contactUsInfo?.email}
										onChange={handleOnChangeContactInfo}
										type='email'
										placeholder=''
									/>
									{contactErrors?.email && (
										<div
											className={"wrong-text w-100"}
											style={{
												color: "red",
												fontSize: "16px",
											}}>
											{contactErrors?.email}
										</div>
									)}
								</div>
								<div className='input-group'>
									<label>عنوان الرسالة</label>
									<input
										name='title'
										value={contactUsInfo?.title}
										onChange={handleOnChangeContactInfo}
										type='text'
										placeholder=' '
									/>
									{contactErrors?.title && (
										<div
											className={"wrong-text w-100"}
											style={{
												color: "red",
												fontSize: "16px",
											}}>
											{contactErrors?.title}
										</div>
									)}
								</div>
								<div className='input-group'>
									<label>تفاصيل الرسالة</label>
									<input
										name='content'
										value={contactUsInfo?.content}
										onChange={handleOnChangeContactInfo}
										type='text'
										placeholder=''
									/>
									{contactErrors?.content && (
										<div
											className={"wrong-text w-100"}
											style={{
												color: "red",
												fontSize: "16px",
											}}>
											{contactErrors?.content}
										</div>
									)}
								</div>
								<button
									disabled={
										!contactUsInfo?.nama &&
										!contactUsInfo?.email &&
										!contactUsInfo?.title &&
										!contactUsInfo?.content
											? true
											: false
									}
									onClick={AddContactUsInfo}
									type='button'>
									ارسال الطلب
								</button>
							</form>
						</div>
					</div>
				</div>
			</div>
			<AlertModal show={showAlertModal} message={message} />
		</>
	);
};

export default ContactUs;
