import React, { useEffect, useState } from "react";

// Third party
import { useNavigate } from "react-router-dom";
import Media from "react-media";
import { toast } from "react-toastify";

// Components
import {
	ProductSwiper,
	ReviewSwiper,
	PartnerSwiper,
	NotFoundData,
	WhatWeOffer,
	LoadingWebSite,
	SouqAtlbhaSwiper,
	FeaturedStores,
	SubscribePackages,
} from "../../index";
import LoadingPage from "../../LoadingPage/LoadingPage";

// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// import required modules
import { Pagination, EffectFade, Autoplay } from "swiper/modules";

// Import Swiper styles
import "swiper/css";
import "swiper/css/effect-fade";
import "swiper/css/pagination";

// css styles
import "./HomeBox.css";

// Icons
import { MainTitle } from "../../index";
import { HiOutlineArrowNarrowLeft } from "react-icons/hi";

// Images
import { VideoIMg } from "../../../assets/Img";
import { useGetSubscribePackagesQuery } from "../../../RTK/Api/subscribePackagesApi";

const HomeBox = ({
	homePageData,
	homeLoadingData,
	isFetching,
	setUseDisplayStores,
}) => {
	const navigate = useNavigate();
	const [getMedia, setMedia] = useState("");

	//
	const { data: packages } = useGetSubscribePackagesQuery();

	const businessStorePack =
		packages && Array.isArray(packages)
			? packages?.find(
					(pack) =>
						pack?.id === 2 ||
						pack?.name === "متجر الاعمال" ||
						pack?.name === "متجر الأعمال" ||
						pack?.name === "باقة متجر الأعمال"
			  )
			: null;

	const myBrandStorePack =
		packages && Array.isArray(packages)
			? packages?.find((pack) => pack?.id === 1 || pack?.name === "باقة المتجر")
			: null;

	const [showStoresSection, setShowStoresSection] = useState(true);
	const [showProductsSection, setShowProductsSection] = useState(true);

	let changeMedia = (e) => {
		e?.small ? setMedia("small") : setMedia("large");
	};

	/* this to handle show and hide products and stores section  */
	useEffect(() => {
		setShowProductsSection(homePageData?.products?.length === 0 ? false : true);

		if (homePageData?.length === 0) {
			setShowStoresSection(true);
		} else {
			setShowStoresSection(homePageData?.stores?.length === 0 ? false : true);
		}
	}, [homePageData?.stores, homePageData?.products]);

	// ------------------------------------------------------------------

	// to disabled right click
	const handleContextMenu = (event) => {
		event.preventDefault(); // Prevent the default right-click behavior

		toast.warning("لا يمكن استخدام خاصية الضغط بالزر الايمن مع هذه القسم", {
			theme: "light",
		});
	};

	// navigate to auth/login by default
	const navigateToMerchantRegister = () => {
		window.location.href = "https://store.atlbha.com/compare-packages";
	};

	const goUpWindow = () => {
		window.scroll(0, 0);
	};
	if (homeLoadingData) {
		return <LoadingPage />;
	}

	return (
		<>
			{/* ================= media ========================= */}
			<Media
				queries={{
					small: "(max-width: 599px)",
				}}
				onChange={(e) => {
					changeMedia(e);
				}}
			/>
			{/* ============= media ============== */}
			<div className='hero' onContextMenu={handleContextMenu}>
				<Swiper
					effect={"fade"}
					loopfillgroupwithblank='true'
					loop={true}
					autoplay={{
						delay: 6000,
						disableOnInteraction: false,
					}}
					pagination={{
						dynamicBullets: true,
						clickable: true,
					}}
					modules={[Pagination, EffectFade, Autoplay]}
					className='my_hero_swiper'>
					{homePageData?.slider1 && (
						<SwiperSlide className='my_hero_swiper_slide'>
							<div
								className='swiper_image'
								style={{
									backgroundImage: `url(${homePageData?.slider1})`,
								}}
							/>

							<div className='content'>
								<h1> منصة اطلبها: ابدأ متجرك الإلكتروني الآن </h1>
								<p>
									حول فكرتك إلى متجر إلكتروني، استفد من خدماتنا الشاملة، بداية
									من إنشاء المتجر الإلكتروني حتى التغليف والشحن
								</p>
								<button
									className='bt-main'
									onClick={() => {
										navigateToMerchantRegister();
									}}>
									أنشئ متجرك الآن
								</button>
							</div>
						</SwiperSlide>
					)}
					{homePageData?.slider2 && (
						<SwiperSlide className='my_hero_swiper_slide'>
							<div
								onClick={() =>
									navigate(`/business-store/${businessStorePack?.id}`)
								}
								className='swiper_image '
								style={{
									backgroundImage: `url(${homePageData?.slider2})`,
									cursor: "pointer",
								}}
							/>
						</SwiperSlide>
					)}

					{homePageData?.slider3 && (
						<SwiperSlide className='my_hero_swiper_slide'>
							<div
								onClick={() =>
									navigate(`/package-info/${myBrandStorePack?.id}`)
								}
								className='swiper_image'
								style={{
									backgroundImage: `url(${homePageData?.slider3})`,
									cursor: "pointer",
								}}
							/>
						</SwiperSlide>
					)}
				</Swiper>
			</div>

			{/* WhatWeOffer */}
			<WhatWeOffer />

			{showProductsSection && (
				<div className='outstanding-products p-main  mb-5 '>
					<div className='container'>
						<MainTitle text={homePageData?.section1} />
						<div className='all'>
							{homePageData?.products?.length ? (
								<ProductSwiper productSwiperData={homePageData?.products} />
							) : (
								<NotFoundData />
							)}
						</div>
					</div>
				</div>
			)}

			<div className='outstanding-products home p-main  mb-5 '>
				<div className='container'>
					<MainTitle text={"منتجات جملة الجملة"} />
					<div style={{ marginTop: "40px" }}>
						{homePageData?.atlbha_products?.length ? (
							<SouqAtlbhaSwiper
								productSwiperData={homePageData?.atlbha_products}
							/>
						) : (
							<NotFoundData />
						)}
					</div>

					<div className='col-12'>
						<div
							className='more-service-btn'
							onClick={() => {
								navigate("/souq_atlbha_products");
								goUpWindow();
							}}>
							المزيد من المنتجات
							<HiOutlineArrowNarrowLeft />
						</div>
					</div>
				</div>
			</div>

			{showStoresSection && (
				<FeaturedStores
					isFetching={isFetching}
					homePageData={homePageData}
					setUseDisplayStores={setUseDisplayStores}
				/>
			)}

			{/* why atlbha */}
			<div className='out-features p-main'>
				<div className='container '>
					<MainTitle text={"لماذا اطلبها ؟"} />
					<div className='all '>
						<a
							href='http://www.youtube.com/channel/UCPypPqJuGFAGHz33RI7c5wg'
							target='blank'>
							<img
								width='100%'
								height='100%'
								src={VideoIMg}
								alt='لماذا اطلبها '
								loading='lazy'
								className=' rounded-3'
							/>
						</a>
					</div>
				</div>
			</div>

			{/* Subscribe packages */}
			<div className='our-package p-main'>
				<div className='container'>
					<MainTitle text={"باقات اطلبها"} />
					<SubscribePackages />
				</div>
			</div>

			<div className='our-review p-main'>
				<div className='container '>
					<MainTitle text={"قالوا عنا"} />
					<div className='all p-main'>
						{homePageData?.comment?.length ? (
							<ReviewSwiper DataReviewSwiper={homePageData?.comment} />
						) : (
							<NotFoundData />
						)}
					</div>
				</div>
			</div>
			{/* =========================== */}
			<div className='our-partners p-main'>
				<div className='container '>
					<MainTitle text={"شركاء النجاح"} />
					<div className='all p-main'>
						{homePageData?.partners?.length ? (
							<PartnerSwiper PartnerDataSwiper={homePageData?.partners} />
						) : (
							<NotFoundData />
						)}
					</div>
				</div>
			</div>
		</>
	);
};

export default HomeBox;
